export const useIconStore = defineStore('icon', {
  state: () => ({
    icons: [],
  }),
  actions: {
    async fetchIcons() {
      const supabase = useSupabaseClient()

      const { data } = await supabase
        .from('icons')
        .select('id, name, solid, outline, mini')
      console.log('store:icon: fetchIcons: data: ', data.length)
      if (data) {
        this.icons = data
      }
    },
    getIconById(id) {
      return this.icons.find((icon) => icon.id === id)
    },
    getIconByName(name) {
      return this.icons.find((icon) => icon.name === name)
    },
    getIconNameById(id) {
      const icon = this.getIconById(id)
      return icon ? icon.name : null
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useIconStore, import.meta.hot))
}
